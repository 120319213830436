import React from 'react'
import colors from '../utils/colors'

const styles = {
  outer: {
    fontFamily: 'Karla',
    fontWeight: 700,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  left: {
    borderRight: '2px solid ' + colors.grey,
    color: colors.black,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '17px',
    lineHeight: '18px',
    textAlign: 'right',
    paddingRight: '4px',
  },
  right: {
    color: colors.green,
    fontSize: '44px',
    lineHeight: '44px',
    paddingLeft: '2px',
  }
}
const Logo = () =>
  <div css={styles.outer}>
    <div css={styles.left}>
      <div>
        WE
      </div>
      <div>
        ARE
      </div>
    </div>
    <div css={styles.right}>
      MCGEES
    </div>
  </div>

export default Logo
