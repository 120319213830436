import React from 'react'
import Helmet from 'react-helmet'
import homePic from '../../static/images/uploads/j-and-t-photo.jpg'

const defaultDescription = "We are the McGees. Hear Us Whisper! We are taking our nomadic house/pet sitting lifestyle international. Follow us as we travel & write about our experiences!"
const defaultTitle = "The McGees | Housesitting and Travel Blog"
const defaultImage = homePic
const defaultKeywords = 'blog, housesitting, travel, wearemcgees'
export const baseURL = 'https://wearemcgees.com'

const mainScript={
  "type": "application/ld+json",
  "innerHTML": `{
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "we are mcgees",
    "url": "https://wearemcgees.com"
  }`
}

export default ({ description=defaultDescription, title=defaultTitle, image=defaultImage, keywords=defaultKeywords, script, path="", type="blog" }) => (
  <Helmet
    title={title}
    meta={[
      { property: 'description', content: description },
      { property: 'keywords', content:  keywords },
      { property: 'image', content: image },
    ]}
    script={[Object.assign(mainScript, script)]}
  >
    <meta property="og:description" content={description} />
    <meta property="twitter:description" content={description} />
    <meta property="og:title" content={title} />
    <meta property="og:image" content={image} />
    <meta property="og:image" content={image} />
    <meta property='og:url' content={baseURL + path} />
    <meta property='og:type' content={type} />
    <meta
      property="twitter:image"
      content={image}
    />
  </Helmet>
)
