import { rhythm } from '../utils/typography'
import colors from './colors';

export const presets = {
  Small: '@media (min-width: 576px)',
  Medium: '@media (min-width: 768px)',
  Large: '@media (min-width: 992px)',
  ExtraLarge: '@media (min-width: 1200px)'
}

export const metrics = {
  defaultMargin: rhythm(1.5),
  defaultPadding: rhythm(3),
  defaultMobilePadding: rhythm(0.75),
  contentWidth: "1000px"
}

export const themeStyles = {
  contentWidth: {
    width: "100%",
    maxWidth: metrics.contentWidth,
    margin: '0 auto'
  },
  textPadding: {
    paddingLeft: metrics.defaultMobilePadding,
    paddingRight: metrics.defaultMobilePadding,
    paddingTop: rhythm(1),
    paddingBottom: rhythm(1),
    [presets.Medium]: {
      paddingLeft: metrics.defaultPadding,
      paddingRight: metrics.defaultPadding,
    },
  }
}