import Typography from 'typography'
import colors from './colors'

const theme = {
  title: 'Custom Theme',
  baseFontSize: '18px',
  baseLineHeight: 1.25,
  scaleRatio: 1.75,
  googleFonts: [
    {
      name: 'Thasadith',
      styles: ['400', '400i', '700', '700i'],
    },
    {
      name: 'Karla',
      styles: ['700'],
    },
  ],
  headerFontFamily: ['Karla', 'sans-serif'],
  bodyFontFamily: ['Thasadith', 'sans-serif'],
  bodyColor: colors.black,
  headerColor: colors.green,
  headerWeight: '700',
  bodyWeight: '400',
  boldWeight: '700',
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    a: {
      color: colors.green,
      // textDecoration: 'none',
    },
    p: {
      marginBottom: rhythm(1),
      paddingBottom: 0,
    },
    'ol,ul': {
      marginLeft: 0,
      paddingLeft: rhythm(1.5),
    },
    'li>ul,li>ol': {
      marginLeft: 0,
      paddingLeft: rhythm(1.5),
    },
    blockquote: {
      ...scale(1 / 5),
      fontWeight: 300,
      fontStyle: 'italic',
      marginLeft: rhythm(1.5),
      marginRight: rhythm(1.5),
    },
    'blockquote cite': {
      ...adjustFontSizeTo(options.baseFontSize),
      fontWeight: options.bodyWeight,
      textTransform: 'uppercase',
    },
    h6: {
      marginTop: rhythm(1.25),
      marginBottom: rhythm(1.25),
    },
    table: {
      ...scale(-1 / 5),
    },
    th: {
      fontWeight: options.boldWeight,
      textTransform: 'uppercase',
    },
    dl: {
      marginLeft: rhythm(3 / 4),
    },
    img: {
      borderRadius: '6px',
    }
  }),
}

const typography = new Typography(theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
