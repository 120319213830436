import React from 'react'
import { Link } from 'gatsby'

import { themeStyles } from '../utils/theme'
import colors from '../utils/colors'

import Helmet from './Helmet'
import Header from './Header'
import Footer from './Footer'

const styles = {
  body: {
    minHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.white,
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.white,
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
    margin: '100px auto',
  }
}

class Template extends React.Component {
  render() {
    const { location, children, hideForm } = this.props
    return (
      <div
        css={styles.body}
      >
        <Helmet />
        <Header />
        <Footer />
        <div css={styles.content}>
          {children}
        </div>
      </div>
    )
  }
}

export default Template
