import React from 'react'
import { Link } from 'gatsby'

import { FaEnvelope } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

import { metrics, presets, themeStyles } from '../utils/theme'
import colors from '../utils/colors'
import { rhythm } from '../utils/typography';

const styles = {
  fixed: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    [presets.Medium]: {
      ...themeStyles.contentWidth,
      paddingLeft: metrics.defaultPadding,
      paddingRight: metrics.defaultPadding,
    },
    zIndex: 999,
  },
  footer: {
    alignItems: 'center',
    backgroundColor: colors.grey,
    border: '1px solid ' + colors.black,
    borderBottomSize: 0,
    color: colors.white,
    display: 'flex',
    flexDirection: 'row',
    fontSize: rhythm(0.7),
    justifyContent: 'center',
    lineHeight: rhythm(0.5),
    padding: rhythm(0.25),
    marginTop: rhythm(0.25),
    [presets.Medium]: {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },
    zIndex: 999,
    '& a': {
      color: 'inherit',
      marginLeft: rhythm(0.3),
      marginRight: rhythm(0.3),
    },
  },
  copyright: {
    marginRight: rhythm(0.75),
  },
}

class Footer extends React.Component {
  render() {
    return (
      <div css={styles.fixed}>
        <div css={styles.footer}>
          <div css={styles.copyright}>
            &copy; {new Date().getFullYear()}, The McGees
          </div>
          <Link to="/contact">
            <FaEnvelope />
          </Link>
          <a href="https://facebook.com/wearemcgees">
            <FaFacebook />
          </a>
          <a href="https://twitter.com/wearemcgees">
            <FaTwitter />
          </a>
          <a href="https://instagram.com/wearemcgees">
            <FaInstagram />
          </a>
        </div>
      </div>
    )
  }
}

export default Footer;