import React from 'react'
import { Link } from 'gatsby'

import { metrics, presets, themeStyles } from '../utils/theme'
import colors from '../utils/colors'
import { rhythm } from '../utils/typography'

//import backgroundImage from '../content/assets/bg.jpg'

import Logo from './Logo'

const styles = {
  fixed: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    [presets.Medium]: {
      ...themeStyles.contentWidth,
      paddingLeft: metrics.defaultPadding,
      paddingRight: metrics.defaultPadding,
    },
    zIndex: 999,
  },
  header: {
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: rhythm(0.25),
    paddingTop: rhythm(1),
  },
  menu: {
    alignItems: 'center',
    backgroundColor: colors.grey,
    border: '1px solid ' + colors.black,
    color: colors.white,
    padding: rhythm(0.25),
    fontSize: rhythm(0.7),
    marginBottom: rhythm(0.25),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [presets.Medium]: {
      borderRadius: '4px',
    },
  },
  link: {
    marginLeft: rhythm(1),
    color: 'inherit',
    textDecoration: 'none',
  }
}

class Header extends React.Component {
  render() {
    return (
      <div css={styles.fixed}>
        <div css={styles.header}>
          <Link to="/" css={[styles.link, {marginLeft: 0}]}>
            <Logo/>
          </Link>
        </div>
        <div css={styles.menu}>
          <Link to="/pages/about" css={[styles.link, {marginLeft: 0}]}>
            about
          </Link>
          <Link to="/places" css={styles.link}>
            places
          </Link>
          <Link to="/map" css={styles.link}>
            map
          </Link>
          <Link to="/animals" css={styles.link}>
            animals
          </Link>
        </div>
      </div>
    )
  }
}

export default Header
